
import { UserRole, Channel } from 'generated-types.d';

import { OrderPermissions } from 'features/orders/orders.types';

import { TypeformConfig } from 'components/typeform';

import { ORDER_LIST_PAGE_LAYOUTS } from './graphql/helpers/order-group.config';
import { OrderPageRoute, OrderStatusSlug } from './orders.types';

const {
  CustomerService,
  SuperAdmin,
  TeamAdmin,
  TeamMember,
  TeamOwner
} = UserRole;

export interface OrderNavOption {
  title: string;
  key: OrderPageRoute;
  id: string;
  filters: string;
  numericFilters: (timezone?: string) => string;
}

export const STANDARD_DELIVERY_TIME = '17:00:00';

export const SAME_DAY_DELIVERY_CUTOFF = '13:00:00';

export const DELIVERY_TIME_COPY = 'Delivery between 10am-5pm';

export const ORDER_NAV: OrderNavOption[] = [
  {
    title: 'Today',
    key: 'today',
    id: 'order-home-nav-today',
    filters: ORDER_LIST_PAGE_LAYOUTS.today.globalFilter.trim(),
    numericFilters: ORDER_LIST_PAGE_LAYOUTS.today.dateRangeFilter
  },
  {
    title: 'Tomorrow',
    key: 'tomorrow',
    id: 'order-home-nav-tomorrow',
    filters: ORDER_LIST_PAGE_LAYOUTS.tomorrow.globalFilter.trim(),
    numericFilters: ORDER_LIST_PAGE_LAYOUTS.tomorrow.dateRangeFilter
  },
  {
    title: 'All Upcoming',
    key: 'upcoming',
    id: 'order-home-nav-upcoming',
    filters: ORDER_LIST_PAGE_LAYOUTS.upcoming.globalFilter.trim(),
    numericFilters: ORDER_LIST_PAGE_LAYOUTS.upcoming.dateRangeFilter
  },
  {
    title: 'Past Orders',
    key: 'past',
    id: 'order-home-nav-past',
    filters: ORDER_LIST_PAGE_LAYOUTS.past.globalFilter.trim(),
    numericFilters: ORDER_LIST_PAGE_LAYOUTS.past.dateRangeFilter
  },
  {
    title: `Valentine's Day`,
    key: 'peak',
    id: 'order-home-nav-peak',
    filters: ORDER_LIST_PAGE_LAYOUTS.peak.globalFilter.trim(),
    numericFilters: ORDER_LIST_PAGE_LAYOUTS.peak.dateRangeFilter
  }
];

export interface SimpleOrderStatus {
  title: string;
  slug: OrderStatusSlug;
}

export interface OrderFlag {
  title: string;
  value: string;
}

export interface OrderSalesChannel {
  title: string;
  slug: Channel;
}

export const ALL_STATUS_SLUGS: OrderStatusSlug[] = [
  OrderStatusSlug.Open,
  OrderStatusSlug.Prepared,
  OrderStatusSlug.OnTheWay,
  OrderStatusSlug.Delivered,
  OrderStatusSlug.FailedDelivery,
  OrderStatusSlug.Cancelled
];

export const FLORIST_STATUS_SLUGS: OrderStatusSlug[] = [
  OrderStatusSlug.Open,
  OrderStatusSlug.Prepared,
  OrderStatusSlug.OnTheWay,
  OrderStatusSlug.Delivered,
  OrderStatusSlug.FailedDelivery
];

export const ORDER_STATUSES: SimpleOrderStatus[] = [
  {
    title: 'With Florist',
    slug: OrderStatusSlug.Open
  },
  {
    title: 'Prepared',
    slug: OrderStatusSlug.Prepared
  },
  {
    title: 'On the way',
    slug: OrderStatusSlug.OnTheWay
  },
  {
    title: 'Delivered',
    slug: OrderStatusSlug.Delivered
  },
  {
    title: 'Failed Delivery',
    slug: OrderStatusSlug.FailedDelivery
  },
  {
    title: 'Cancelled',
    slug: OrderStatusSlug.Cancelled
  }
];

export const ORDER_DELIVERY_TYPES: OrderFlag[] = [
  {
    title: 'Nationwide',
    value: 'nationwide'
  },
  {
    title: 'Local',
    value: 'local'
  }
];

export const ORDER_ADDRESS_TYPES: OrderFlag[] = [
  {
    title: 'Commercial',
    value: 'commercial'
  },
  {
    title: 'Residential',
    value: 'residential'
  }
];

export const ORDER_CHANNELS: OrderSalesChannel[] = [
  {
    title: 'Floom',
    slug: Channel.Floom
  },
  {
    title: 'Custom website',
    slug: Channel.Website
  }
];

export const ORDER_PERMISSIONS: OrderPermissions = {
  status: {
    restrictedRoles: [TeamMember],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  deliverAt: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  giftMessages: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  deliveryInstructions: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  deliverySafePlace: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  shippingAddress: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  billingAddress: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  emailAddress: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  },
  changeNewStatus: {
    restrictedRoles: [SuperAdmin, CustomerService],
    floristChannelRoles: []
  },
  floomNote: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: []
  },
  floristNote: {
    restrictedRoles: [],
    floristChannelRoles: []
  },
  senderDetails: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin],
    floristChannelRoles: [TeamOwner, TeamAdmin]
  }
};

export const ORDER_TYPEFORM: TypeformConfig[] = [
  {
    formId: 'XAO3tUZU',
    copy: 'Raise substitution',
    subCopy: 'If you need to substitute a stem for a different stem',
    isVisible: true
  },
  {
    formId: 'PwCUOzgN',
    copy: 'Request to cancel',
    subCopy: 'If you can\'t fulfil the order',
    isVisible: true
  },
  {
    formId: 'vFajaJyG',
    copy: 'Notify failed delivery',
    subCopy: 'Provide us with details on why delivery of the order was not possible',
    isVisible: true
  },
  {
    formId: 'bv8N72EL',
    copy: 'Proof of delivery',
    subCopy: `Provide the customer with details on where the order was left, after successful delivery`,
    isVisible: true
  }
];
