import { FC, Fragment, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Adjustment, Order } from 'generated-types';
import moment from 'moment';
import { Box } from 'rebass';

import { CurrencyService, GraphQL, PermissionsService } from 'lib';

import { colors } from 'utils/rebass-theme';

const STANDARD_PENALTY_FEE_AMOUNT = {
  USD: 20,
  GBP: 10
};

export type QuestionMatrix = {
  group: string;
  label: string;
  isEnabled: () => boolean;
  options?: {
    isEnabled: () => boolean;
    items: QuestionOption[];
  };
};

export type QuestionOption = {
  label: string;
  value: string;
  isEnabled: () => boolean;
  productPercentage?: number;
  split?: {
    florist: number;
    floom: number;
  };
  fee?: number;
};

export const generateQuestionMatrix = (order: Order): QuestionMatrix[] => {
  return [
    {
      group: 'customerCancellation',
      label: 'Customer requested cancellation',
      isEnabled: () => true,
      options: {
        isEnabled: () => (order?.orderItems && order?.orderItems.length > 1) || false,
        items: [
          {
            label: 'Customer no longer wants the order',
            value: 'customerNoLongerWantsOrder',
            isEnabled: () => true
          },
          {
            label: 'Partial product cancellation',
            value: 'partialProductCancellation',
            isEnabled: () => (order?.orderItems && order?.orderItems.length > 1) ? true : false,
            productPercentage: 1
          }
        ]
      }
    },
    {
      group: 'floristCancellation',
      label: 'Florist requested cancellation',
      isEnabled: () => true,
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'Requested on day of delivery',
            value: 'requestedOnDayOfDelivery',
            isEnabled: () => true,
            fee: STANDARD_PENALTY_FEE_AMOUNT[order?.currency || 'GBP']
          },
          {
            label: 'Requested before day of delivery',
            value: 'requestedBeforeDayOfDelivery',
            isEnabled: () => true,
            fee: order?.payment?.stripeFee || 0
          }
        ]
      }
    },
    {
      group: 'floristRequestDelay',
      label: 'Florist requested delay',
      isEnabled: () => true,
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'Customer accepted delay',
            value: 'customerAcceptedDelay',
            isEnabled: () => true,
            fee: 0,
            split: {
              florist: 1,
              floom: 0
            }
          },
          {
            label: 'Customer did not accept delay',
            value: 'customerDidntAcceptedDelay',
            isEnabled: () => true,
            fee: order?.payment?.stripeFee || 0
          }
        ]
      }
    },
    {
      group: 'incompleteDelivery',
      label: 'Incomplete delivery',
      isEnabled: () => {
        return true;
        // return moment().tz(order?.merchant?.timezone).isBefore(moment(order?.deliverAt));
      },
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'Responsive FL: No POD or invalid POD provided',
            value: 'deliveredNoPOD',
            isEnabled: () => true,
            fee: order?.payment?.stripeFee || 0
          },
          {
            label: 'Unresponsive FL: No POD or invalid POD provided',
            value: 'deliveredUnresponsiveFL',
            isEnabled: () => true,
            fee: STANDARD_PENALTY_FEE_AMOUNT[order?.currency || 'GBP']
          },
          {
            label: 'Not marked as delivered by FL',
            value: 'undeliveredFLCancel',
            isEnabled: () => true,
            fee: STANDARD_PENALTY_FEE_AMOUNT[order?.currency || 'GBP']
          },
          {
            label: 'Delivered late (24hrs+)',
            value: 'deliveredLate24hrs',
            isEnabled: () => true,
            split: {
              florist: 1,
              floom: 0
            },
            fee: STANDARD_PENALTY_FEE_AMOUNT[order?.currency || 'GBP']
          },
          {
            label: 'Delivered late (same day but after cut off)',
            value: 'deliveredLateAfterCutOff',
            isEnabled: () => true,
            split: {
              florist: 1,
              floom: 0
            }
          },
          {
            label: 'DPD Delay',
            value: 'dpdDelay',
            isEnabled: () => true,
            split: {
              florist: 1,
              floom: 0
            }
          }
        ]
      }
    },
    {
      group: 'productIssues',
      label: 'Product issues',
      isEnabled: () => {
        return true;
        // return moment().tz(order?.merchant?.timezone).isBefore(moment(order?.deliverAt));
      },
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'Moderate Sub Issue - Floom not informed',
            value: 'moderateSubIssueFloomNotInformed',
            isEnabled: () => true,
            productPercentage: 0.3,
            split: {
              florist: 1,
              floom: 0
            }
          },
          {
            label: 'Moderate Sub Issue - Floom informed',
            value: 'moderateSubIssueFloomInformed',
            isEnabled: () => true,
            productPercentage: 0.3,
            split: {
              florist: 0,
              floom: 1
            }
          },
          {
            label: 'Severe Sub Issue - Floom not informed',
            value: 'severeSubIssueFloomNotInformed',
            isEnabled: () => true,
            fee: STANDARD_PENALTY_FEE_AMOUNT[order?.currency || 'GBP']
          },
          {
            label: 'Severe Sub Issue - Floom informed',
            value: 'severeSubIssueFloomInformed',
            isEnabled: () => true,
            split: {
              florist: 0,
              floom: 1
            }
          },
          {
            label: 'Size - Moderate',
            value: 'sizeModerate',
            isEnabled: () => true,
            productPercentage: 0.5,
            split: {
              florist: 1,
              floom: 0
            }
          },
          {
            label: 'Size - Severe',
            value: 'sizeSevere',
            isEnabled: () => true,
            fee: order?.payment?.stripeFee || 0
          },
          {
            label: 'Damaged',
            value: 'damaged',
            isEnabled: () => true
          }
        ]
      }
    },
    {
      group: 'substitutionRequest',
      label: 'Substitution request',
      isEnabled: () => {
        return true;
        // return moment().tz(order?.merchant?.timezone).isBefore(moment(order?.deliverAt));
      },
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'FL requested before 11am',
            value: 'floristRequestedToMakeLargeSubstitutions',
            isEnabled: () => true
          },
          {
            label: 'FL requested after 11am',
            value: 'floristSetHolidayOnFloomX',
            isEnabled: () => true,
            fee: order?.payment?.stripeFee || 0
          }
        ]
      }
    },
    {
      group: 'techIssues',
      label: 'Tech issues',
      isEnabled: () => true,
      options: {
        isEnabled: () => true,
        items: [
          {
            label: 'Order glitch',
            value: 'orderGlitch',
            isEnabled: () => true
          },
          {
            label: 'Holidays',
            value: 'holidays',
            isEnabled: () => true
          },
          {
            label: 'Coupon not applied',
            value: 'couponNotApplied',
            isEnabled: () => true,
            split: {
              florist: 0,
              floom: 1
            }
          },
          {
            label: 'Duplicate charge',
            value: 'duplicateCharge',
            isEnabled: () => true
          }
        ]
      }
    },
    {
      group: 'missingAddon',
      label: 'Missing add-on',
      isEnabled: () => true,
      options: {
        isEnabled: () => false,
        items: [
          {
            label: 'Florist at fault',
            value: 'floristAtFault',
            isEnabled: () => false,
            split: {
              florist: 1,
              floom: 0
            }
          }
        ]
      }
    }
  ];
};

export const ORDER_DATA_QUERY = gql`
  query Order(
    $id: ID!
  ) {
    order(
      where: {
        id: $id
      }
    ) {
      currency
      orderedAt
      deliverAt
      merchant {
        timezone
      }
      orderDeliveryConfig {
        price
      }
      orderItems {
        id
        productSnapshot
        addOnsSnapshot
        price
        quantity
      }
      orderDiscount {
        total
      }
      payment {
        stripeFee
        serviceFee
      }
      transactions(
        orderBy: createdAt_ASC
        where: {
          source_in: [
            Order
            OrderRefund
          ]
        }
      ) {
        id
        createdAt
        netAmount
        type
        source
        remittanceType
        adjustment {
          id
          reason
          reasonFreeform
          type
        }
      }
    }
  }
`;

export const CREATE_REFUND_ADJUSTMENT = gql`
  mutation CreateRefundAdjustment($data: AdjustmentCreateRefundInput!) {
    createRefundAdjustment(data: $data) {
      id
    }
  }
`;

export const getOrderData = async (orderId: string): Promise<any> => {
  const orderQueryResult = await GraphQL.query<{ order: any }>(
    ORDER_DATA_QUERY,
    {
      id: orderId
    }
  );

  return orderQueryResult.data;
};

export const RefundForm: FC<{
  orderId: string;
  closeModal: () => void;
}> = ({
  orderId,
  closeModal
}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [refundReason, setRefundReason] = useState<string>('');
  const [secondaryRefundReason, setSecondaryRefundReason] = useState<string>('');
  const [refundReasonFreeform, setRefundReasonFreeform] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [addOnPrice, setAddOnPrice] = useState<number | undefined>(undefined);
  const [overrideCustomerRefund, setOverrideCustomerRefund] = useState<number | undefined>(undefined);
  const [overrideMerchantRemittance, setOverrideMerchantRemittance] = useState<number | undefined>(undefined);
  const [overrideFloomRemittance, setOverrideFloomRemittance] = useState<number | undefined>(undefined);

  const { data } = useQuery({
    queryKey: ['refund-modal', orderId],
    queryFn: () => getOrderData(orderId),
    enabled: !!orderId
  });

  const order = data?.order;
  const currency = order?.currency || 'GBP';
  const inclusiveTaxRate = (order?.currency === 'GBP' ? 0.2 : 0);

  const questions = !!order ? generateQuestionMatrix(order) : [];

  const remainingBalance = ((): number => {
    const balance = data?.order?.transactions
      .filter((transaction: any) => {
        const isOrderTransaction = ['Order', 'OrderRefund'].includes(transaction.source);
        const hasNoAdjustment = !transaction.adjustment;
        const isOrderAdjustment = transaction.adjustment?.type === 'Refund';

        return isOrderTransaction && (isOrderAdjustment || hasNoAdjustment);
      })
      .reduce((acc: number, transaction: any) => {
        if (transaction.type === 'Credit') {
          return acc + transaction.netAmount;
        }

        return acc - transaction.netAmount;
      }, 0);

    return Math.round(balance * 100) / 100;
  })();

  const flatFeeRefunding = ((): number => {
    switch (secondaryRefundReason) {
      case 'deliveredLateAfterCutOff':
      case 'customerAcceptedDelay':
      case 'dpdDelay':
        return order?.orderDeliveryConfig?.price || 0;

      case 'couponNotApplied':
        return order?.orderDiscount?.total || 0;

      default:
        return 0;
    }
  })();

  const selectedQuestion = ((): QuestionMatrix | undefined => {
    return questions.find((question: QuestionMatrix) => question.label === refundReason);
  })();

  const selectedOption = ((): QuestionOption | undefined => {
    if (!selectedQuestion) {
      return;
    }

    return selectedQuestion.options?.items.find((option: QuestionOption) => option.value === secondaryRefundReason);
  })();

  const splits = ((): { florist: number; floom: number } => {
    return selectedOption?.split || { florist: 0.75, floom: 0.25 };
  })();

  /** floomFee */
  const floristPenaltyFee = ((): number => {
    if (selectedOption?.fee === order?.payment?.stripeFee) {
      const externalFeeVat = order?.payment?.stripeFee * inclusiveTaxRate;

      return Math.round((order?.payment?.stripeFee + externalFeeVat) * 100) / 100;
    }

    return selectedOption?.fee || 0;
  })();

  const productPercentage = ((): number | undefined => {
    if (!selectedQuestion) {
      return;
    }

    return selectedQuestion.options?.items.find((option: QuestionOption) => option.value === secondaryRefundReason)?.productPercentage;
  })();

  const selectedProduct = ((): any | undefined => {
    if (order?.orderItems.length === 1) {
      return order?.orderItems[0];
    }

    return order?.orderItems.find((item: any) => item.id === productId);
  })();

  /** grossAmount */
  const customerRefund = ((): number => {
    if ((overrideCustomerRefund !== undefined) && overrideCustomerRefund >= 0) {
      return overrideCustomerRefund;
    }

    if (flatFeeRefunding > 0) {
      const flatFee = Math.round((flatFeeRefunding) * 100) / 100;

      return (flatFee > remainingBalance) ? remainingBalance : flatFee;
    }

    if (selectedProduct && productPercentage) {
      const productPrice = Math.round((selectedProduct?.price * productPercentage) * 100) / 100;

      return (productPrice > remainingBalance) ? remainingBalance : productPrice;
    }

    if (addOnPrice) {
      return (addOnPrice > remainingBalance) ? remainingBalance : addOnPrice;
    }

    return Math.round((remainingBalance) * 100) / 100;
  })();

  /** floristRemittance */
  const floristRemittance = ((): number => {
    if ((overrideMerchantRemittance !== undefined) && overrideMerchantRemittance >= 0) {
      return overrideMerchantRemittance;
    }

    const deliveryFee = order?.orderDeliveryConfig?.price || 0;
    const externalFee = order?.payment?.stripeFee;
    const externalFeeVat = order?.payment?.stripeFee * inclusiveTaxRate;

    if (flatFeeRefunding > 0) {
      return splits.florist > 0 ? Math.round((customerRefund * splits.florist) * 100) / 100 : 0;
    }

    const productPrice = (selectedProduct?.price * (productPercentage || 1))
      || order?.orderItems.reduce((acc: number, item: any) => acc + item.price, 0);

    const amountForProduct = splits.florist > 0 ? Math.round((productPrice * splits.florist) * 100) / 100 : 0;

    if (selectedProduct && productPercentage) {
      return Math.round((amountForProduct) * 100) / 100;
    }

    if (addOnPrice) {
      return addOnPrice;
    }

    return Math.round((
      amountForProduct
      + deliveryFee
      - externalFee
      - externalFeeVat
    ) * 100) / 100;
  })();

  const floristTotal = ((): number => {
    return Math.round((floristRemittance + floristPenaltyFee) * 100) / 100;
  })();

  /** floomRemittance */
  const floomRemittance = ((): number => {
    if ((overrideFloomRemittance !== undefined) && overrideFloomRemittance >= 0) {
      return overrideFloomRemittance;
    }

    if (flatFeeRefunding > 0) {
      return splits.floom > 0 ? Math.round((customerRefund * splits.floom) * 100) / 100 : 0;
    }

    const productPrice = (selectedProduct?.price * (productPercentage || 1))
      || order?.orderItems.reduce((acc: number, item: any) => acc + item.price, 0);

    const adjustedProductPrice = remainingBalance < productPrice ? remainingBalance : productPrice;

    const amountForProduct = splits.floom > 0 ? Math.round((adjustedProductPrice * splits.floom) * 100) / 100 : 0;

    if (selectedProduct && productPercentage) {
      return Math.round((amountForProduct) * 100) / 100;
    }

    if (addOnPrice) {
      return 0;
    }

    const serviceFee = order?.payment?.serviceFee || 0;
    const externalFee = order?.payment?.stripeFee;
    const externalFeeVat = order?.payment?.stripeFee * inclusiveTaxRate;

    const feeAmount = ((): number => {
      const baseFee = (externalFee * 2) + externalFeeVat;

      if (!selectedOption?.fee) {
        return baseFee;
      }

      if (selectedOption?.fee === order?.payment?.stripeFee) {
        return externalFee;
      }

      return baseFee - selectedOption?.fee;
    })();

    return Math.round((
      amountForProduct
      + serviceFee
      + feeAmount
    ) * 100) / 100;
  })();

  const customerRefundCopy = ((): string => {
    if (flatFeeRefunding > 0) {
      switch (refundReason) {
        case 'Delivery complaint':
          return `100% delivery cost`;

        default:
          return '';
      }
    }

    if (!!selectedOption?.productPercentage) {
      return `${selectedOption.productPercentage * 100}% of product price`;
    }

    return `100% of order price`;
  })();

  const handleRefund = async (): Promise<any> => {
    setIsSaving(true);

    try {
      const result = await GraphQL.query<{ createRefundAdjustment: Pick<Adjustment, 'id'> }>(
        CREATE_REFUND_ADJUSTMENT,
        {
          data: {
            reason: refundReason,
            secondaryReason: secondaryRefundReason,
            reasonFreeform: refundReasonFreeform || '',
            grossAmount: customerRefund,
            floomRemittance: floomRemittance,
            merchantRemittance: floristRemittance,
            floomFee: floristPenaltyFee,
            order: {
              id: orderId
            }
          }
        }
      );

      resetForm();
      queryClient.invalidateQueries(['refund-modal', orderId]);

      return result.data?.createRefundAdjustment;
    } catch (e: any) {
      setIsSaving(false);
      setError(e.message);

      return Promise.reject(e);
    }
  };

  const isValid = ((): boolean => {
    if (refundReason === '') {
      return false;
    }

    if (refundReason === 'Product issues' && secondaryRefundReason === '') {
      return false;
    }

    if (refundReason === 'Product issues' && !!productPercentage && !selectedProduct) {
      return false;
    }

    return true;
  })();

  useEffect(() => {
    resetForm();
  }, []);

  const resetForm = (): void => {
    setIsSaving(false);
    setRefundReason('');
    setProductId('');
    setError('');
    setSecondaryRefundReason('');
  };

  const handlePrimaryReasonChange = (value: string): void => {
    resetForm();
    setRefundReason(value);
  };

  const handleSecondaryReasonChange = (value: string): void => {
    setProductId('');
    setSecondaryRefundReason(value);
  };

  return (
    <div
      className="px-6 py-5 overflow-y-auto"
      style={{ minHeight: '948px' }}
    >
      <Fragment>
        <div className="grid grid-cols-2 gap-6">
          <div className="col-span-1 mb-5">
            {/* {remainingBalance === 0 ? (
              <p className="text-sm">
                This order has already been fully refunded
              </p>
            ) : ( */}
            <Fragment>
              <div className="mb-4">
                <label
                  htmlFor="primaryReason"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                        Why is a refund being issued?
                </label>
                <select
                  id="primaryReason"
                  name="primaryReason"
                  value={refundReason}
                  onChange={e => handlePrimaryReasonChange(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue="Please select"
                >
                  <option value="">Please select</option>
                  {questions
                    .filter(q => !!q.isEnabled())
                    .map((question: QuestionMatrix) => (
                      <option
                        key={question.group}
                        value={question.label}
                      >
                        {question.label}
                      </option>
                    ))}
                </select>
              </div>

              {(!!selectedQuestion && !!selectedQuestion.options?.isEnabled()) && (
                <div className="mb-4">
                  <label
                    htmlFor="secondaryReason"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                      Select a secondary reason
                  </label>
                  <select
                    id="secondaryReason"
                    name="secondaryReason"
                    value={secondaryRefundReason}
                    onChange={e => handleSecondaryReasonChange(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue="Please select"
                  >
                    <option>Please select</option>
                    {selectedQuestion.options.items.map((option: QuestionOption) => {
                      return (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {(!!productPercentage && order?.orderItems.length > 1) && (
                <div className="mb-4">
                  <p
                    className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                      Select the product being refunded
                  </p>
                  {order?.orderItems.map((item: any) => (
                    <div
                      key={item.id}
                      className="mb-2"
                    >
                      <div className="border border-slate-300 flex items-center justify-between rounded-md p-3 text-sm leading-6">
                        <div className="flex">
                          <input
                            id={item.id}
                            style={{ marginTop: '5px' }}
                            aria-describedby={`${item.id}-description`}
                            name={`product-${item.id}`}
                            type="radio"
                            checked={productId === item.id}
                            onChange={() => setProductId(item.id)}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-3"
                          />
                          <label
                            className="cursor-pointer"
                            htmlFor={item.id}
                          >
                            <p
                              className="font-medium text-gray-900"
                            >
                              {item.productSnapshot.title}
                            </p>
                            {' '}
                            <p
                              id={`${item.id}-description`}
                              className="text-gray-500"
                            >
                                    Qty: {item.quantity}
                            </p>
                          </label>
                        </div>
                        <p>
                          {CurrencyService.renderCurrencySymbol(currency)}{item.price}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {refundReason === 'Missing add-on' && (
                <div className="mb-4">
                  <p
                    className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                  >
                      Select the add-on being refunded
                  </p>
                  {order?.orderItems.map((item: any) => (
                    <Fragment key={item.id}>
                      {item.addOnsSnapshot?.map((addOn: any) => (
                        <div
                          key={addOn.id}
                          className="mb-2"
                        >
                          <div className="border border-slate-300 flex items-center justify-between rounded-md p-3 text-sm leading-6">
                            <div className="flex">
                              <input
                                id={addOn.id}
                                style={{ marginTop: '5px' }}
                                aria-describedby={`${addOn.id}-description`}
                                name={`product-${addOn.id}`}
                                type="radio"
                                checked={addOnPrice === addOn.price}
                                onChange={() => setAddOnPrice(addOn.price)}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-3"
                              />
                              <label
                                className="cursor-pointer"
                                htmlFor={addOn.id}
                              >
                                <p
                                  className="font-medium text-gray-900"
                                >
                                  {addOn.title}
                                </p>
                                {' '}
                                <p
                                  id={`${addOn.id}-description`}
                                  className="text-gray-500"
                                >
                                  Qty: {addOn.quantity}
                                </p>
                              </label>
                            </div>
                            <p>
                              {CurrencyService.renderCurrencySymbol(currency)}{addOn.price}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </div>
              )}

              {!!PermissionsService.isSuperAdmin() && (
                <Fragment>
                  <div className="mb-4">
                    <label
                      htmlFor="reasonFreeform"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Override customer refund
                    </label>
                    <input
                      type="number"
                      name="overrideCustomerRefund"
                      id="overrideCustomerRefund"
                      value={overrideCustomerRefund}
                      onChange={e => setOverrideCustomerRefund(parseFloat(e.target.value))}
                      className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="reasonFreeform"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Override merchant remittance
                    </label>
                    <input
                      type="number"
                      name="overrideMerchantRemittance"
                      id="overrideMerchantRemittance"
                      value={overrideMerchantRemittance}
                      onChange={e => setOverrideMerchantRemittance(parseFloat(e.target.value))}
                      className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="reasonFreeform"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Override floom remittance
                    </label>
                    <input
                      type="number"
                      name="overrideFloomRemittance"
                      id="overrideFloomRemittance"
                      value={overrideFloomRemittance}
                      onChange={e => setOverrideFloomRemittance(parseFloat(e.target.value))}
                      className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </Fragment>
              )}

              <div className="mb-4">
                <label
                  htmlFor="reasonFreeform"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                        Provide more detail
                </label>
                <textarea
                  name="reasonFreeform"
                  value={refundReasonFreeform}
                  onChange={e => setRefundReasonFreeform(e.target.value)}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Please provide more detail"
                />
              </div>
            </Fragment>
            {/* )} */}
          </div>
          <div className="col-span-1">
            {order?.transactions.length > 1 && (
              <div className="mb-6">
                <h3 className="text-base font-bold mb-2">Transaction history</h3>
                <div className="bg-slate-100 p-4 w-100 rounded-lg mb-3">
                  {order?.transactions
                    .filter((transaction: any) => {
                      const hasNoAdjustment = !transaction.adjustment;
                      const isOrderAdjustment = transaction.adjustment?.type === 'Refund';

                      return hasNoAdjustment || isOrderAdjustment;
                    })
                    .map((transaction: any) => (
                      <div
                        key={transaction.id}
                        className="flex flex-col mb-2"
                      >
                        <p className="text-xs text-slate-400">
                          {moment(transaction.createdAt).format('DD-MM-YY — h:mma')}
                        </p>
                        <div className="grid grid-cols-8">
                          <div className="col-span-6 text-sm mr-2">
                            {transaction.type === 'Credit' && (
                              <span className="font-bold">Order payment</span>
                            )}
                            {!!transaction.adjustment && (
                              <Fragment>
                                <p className="mb-1">
                                  <span className="font-bold">{transaction.adjustment.type}{' '}</span>
                                  <span className="text-xs text-slate-600">
                                    ({transaction.adjustment.reason})
                                  </span>
                                </p>
                                <div className="text-xs">
                                  <div className="whitespace-pre-line">{transaction.adjustment.reasonFreeform}</div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                          <div className="col-span-2 text-right text-md font-bold text-slate-400">
                            {transaction.type === 'Debit' && '-'}{CurrencyService.renderCurrencySymbol(currency)}{transaction.netAmount}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className='h-[1px] border border-slate-200 my-4' />
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-bold">
                          Remaining balance
                    </p>
                    <p className="text-md font-bold text-slate-400">
                      {CurrencyService.renderCurrencySymbol(currency)}{remainingBalance}
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!!isValid && (
              <Fragment>
                <h3 className="text-base font-bold mb-3">Refund breakdown</h3>
                <div className="bg-slate-100 p-4 w-100 rounded-lg mb-3">
                  <div className="flex items-center justify-between mb-1">
                    <div className="text-sm">
                      <p><span className="text-sm font-bold">Customer</span> refund</p>
                      <p>{customerRefundCopy}</p>
                    </div>
                    <p className="text-md font-bold">
                      {CurrencyService.renderCurrencySymbol(currency)}{customerRefund.toFixed(2)}
                    </p>
                  </div>
                  <div className="h-[1px] border border-slate-200 my-3" />
                  {floristPenaltyFee > 0 ? (
                    <Fragment>
                      <h3 className="text-sm font-bold mb-3">Florist</h3>
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm">
                          Pays ({overrideMerchantRemittance ? 'Custom' : splits.florist * 100}%)
                        </p>
                        <p className="text-md font-bold text-slate-400">
                          {CurrencyService.renderCurrencySymbol(currency)}{floristRemittance.toFixed(2)}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm">
                          Penalty fee{floristPenaltyFee === order?.payment?.stripeFee && ' (Stripe fee)'}
                        </p>
                        <p className="text-md font-bold text-slate-400">
                          {floristPenaltyFee > 0 ? (
                            <span>{CurrencyService.renderCurrencySymbol(currency)}{floristPenaltyFee}</span>
                          ) : (
                            <span>No penalty</span>
                          )}
                        </p>
                      </div>
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-sm">
                          Total
                        </p>
                        <p className="text-md font-bold text-slate-700">
                          {CurrencyService.renderCurrencySymbol(currency)}{floristTotal.toFixed(2)}
                        </p>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="flex items-center justify-between mb-1">
                      <p className="text-sm">
                        <span className="text-sm font-bold">Florist</span> pays ({overrideMerchantRemittance ? 'Custom' : splits.florist * 100}%)
                      </p>
                      <p className="text-md font-bold text-slate-700">
                        {CurrencyService.renderCurrencySymbol(currency)}{floristTotal.toFixed(2)}
                      </p>
                    </div>
                  )}
                  <div className="h-[1px] border border-slate-200 my-3" />
                  <div className="flex items-center justify-between mb-1">
                    <p className="text-sm">
                      <span className="text-sm font-bold">Floom</span> pays ({overrideFloomRemittance ? 'Custom' : splits.floom * 100}%)
                    </p>
                    <p className="text-md font-bold text-slate-700">
                      {CurrencyService.renderCurrencySymbol(currency)}{floomRemittance.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-3 mb-4">
                  <Box
                    onClick={() => closeModal()}
                    disabled={false}
                  >
                    <button
                      disabled={false}
                      className="bg-slate-300 h-14 rounded-md sm:rounded-lg w-full text-slate-800
                              justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                              disabled:opacity-30"
                    >
                      <span>Cancel</span>
                    </button>
                  </Box>
                  <Box
                    disabled={!isValid || isSaving}
                    onClick={() => handleRefund()}
                  >
                    <button
                      disabled={!isValid || isSaving}
                      style={{
                        backgroundColor: colors.floomMidnightBlue
                      }}
                      className="h-14 rounded-md sm:rounded-lg w-full text-white
                              justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                              disabled:opacity-30"
                    >
                      {isSaving ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0
                        12h4zm2 5.291A7.962 7.962 0 014 12H0c0
                        3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      ) : (
                        <span>Refund ({CurrencyService.renderCurrencySymbol(currency)}{customerRefund.toFixed(2)})</span>
                      )}
                    </button>
                  </Box>
                  {!!error && (
                    <div className="mt-2 col-span-2 rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-bold text-red-800">
                            Error submitting refund
                          </p>
                          <div className="mt-2 text-sm text-red-700">
                            {error}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    </div>
  );
};
