import { FC, useEffect } from 'react';

import 'styles/tailwind.css';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import moment from 'moment';
import store from 'stores';
import { shallow } from 'zustand/shallow';

import MerchantStore from 'stores/merchant/merchant-store';

import { colors } from 'utils/rebass-theme';

import ImgixImage from 'components/imgix-image';

import { getProducts, getUpcomingPeriod } from '../queries';
import { usePromotedStore } from '../store';

const merchantStore = store.merchantStore as MerchantStore;

const PromotedOverview: FC = () => {
  const {
    setMerchant,
    upcomingPeriod,
    setUpcomingPeriod,
    merchantTimezone
  } = usePromotedStore(
    state => ({
      setMerchant: state.setMerchant,
      setMerchantSelectablePostcodes: state.setMerchantSelectablePostcodes,
      setUpcomingPeriod: state.setUpcomingPeriod,
      upcomingPeriod: state.upcomingPeriod,
      merchantTimezone: state.merchantTimezone
    }),
    shallow
  );

  const { data: products } = useQuery({
    queryKey: ['promotedProducts', merchantStore.merchant?.id],
    queryFn: () => getProducts(merchantStore.merchant?.id),
    enabled: !!merchantStore.merchant?.id
  });

  useEffect(() => {
    setMerchant(merchantStore.merchant!);
  }, [merchantStore]);

  const { isLoading } = useQuery({
    queryKey: ['upcomingPeriod'],
    queryFn: () => getUpcomingPeriod(
      setUpcomingPeriod,
      merchantStore.merchant!.timezone
    )
  });

  const periodDates = !!upcomingPeriod
    ? `${moment(upcomingPeriod.startDate).tz(merchantTimezone).format('Do MMMM')} - ${moment(upcomingPeriod.endDate).tz(merchantTimezone).format('Do MMMM')}`
    : null;

  const renderDropTime = (): string => {
    return moment(upcomingPeriod?.dropDate).tz(merchantTimezone).format('dddd, MMMM Do YYYY, ha');
  };

  const renderDropEndTime = (): string => {
    return moment(upcomingPeriod?.startDate).tz(merchantTimezone).subtract(1, 'second').format('dddd, MMMM Do YYYY, h:mma');
  };

  const hasPeriodDropped = !!upcomingPeriod ? moment().tz(merchantTimezone).isAfter(moment(upcomingPeriod?.dropDate).tz(merchantTimezone)) : false;

  if (!merchantStore.merchant) {
    return (
      <div className="max-w-5xl mx-auto px-6 md:px-8 py-6">
        <div className="mb-4">
          <h1 className="text-lg">Promoted</h1>
        </div>
        <div className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg mb-5">
          <div className="pl-4 pt-6 sm:pl-6">
            Select a merchant
          </div>
        </div>
      </div>
    );
  }

  const currency = merchantStore!.merchant!.currency;

  const timespan = !!upcomingPeriod
    ? moment(upcomingPeriod.endDate).diff(moment(upcomingPeriod.startDate), 'weeks')
    : null;

  return (
    <div className="max-w-5xl mx-auto px-6 md:px-8 py-6">
      <div className="flex-wrap justify-between items-center">
        <div className="mb-3 flex items-baseline justify-between">
          <h1 className="text-lg">Valentine&apos;s Day Promotion</h1>
          {isLoading ? (
            <div className="h-5 w-24 mt-1 bg-gray-200 rounded animate-pulse" />
          ) : !!periodDates && (
            <div>
              <div className="text-sm bg-white border border-slate-200 rounded-md sm:rounded-lg px-4 pt-2 pb-3 leading-6 text-slate-800 mb-1">
                <p className="flex items-center">
                  <span className="mr-3 text-lg">📅</span>
                  Valentine&apos;s Promoted: <span className="mx-2 font-bold text-base">{periodDates}</span> (2 weeks)
                </p>
                {hasPeriodDropped ? (
                  <p className="text-right text-xs text-slate-500">Booking closes: {renderDropEndTime()}</p>
                ) : (
                  <p className="text-right text-xs text-slate-500">Booking opens: {renderDropTime()}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg mb-5">
        <div className="px-4 py-6 sm:px-6">
          <h3 className="text-lg leading-6 font-medium mb-2">
            Promote your brand at the top of your {currency === 'GBP' ? 'favourite postcodes' : 'favorite zipcodes'}
          </h3>
          <p className="max-w-2xl text-sm text-slate-500 mb-5 leading-6">
            Your products will be displayed in your own segment on Floom.com in the {currency === 'GBP' ? 'postcodes' : 'zipcodes'} you promote.
            Every {currency === 'GBP' ? 'postcode' : 'zipcode'} can only have one promoted florist during the Valentine&apos;s period, so act fast to secure your choice.
          </p>
          <Link
            to={NavPages.PromotedUpcomingPostcodes}
          >
            <button
              style={{
                backgroundColor: colors.floomMidnightBlue
              }}
              className="h-10 px-4 pb-1 rounded-md sm:rounded-lg text-white
              justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
              disabled:opacity-30"
            >
              <span>Book promoted {currency === 'GBP' ? 'postcodes' : 'zipcodes'}</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg mb-5">
        <div className="px-4 sm:py-7 sm:px-6">
          <h4 className="text-base font-medium mb-6">
            How promoted {currency === 'GBP' ? 'postcodes' : 'zipcodes'} work
          </h4>
          <div className="grid sm:grid-cols-2 gap-x-10 gap-y-6">
            <div className="flex">
              <p className="mb-2 mr-3 -mt-0.5">⏰</p>
              <div>
                <h5 className="text-md font-medium mb-2">The drop</h5>
                <p className="text-sm text-slate-500 leading-6">
                  Keep an eye out for the upcoming drop. Drops are live for a few days and close at 11:59pm the day before the period starts.
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="mb-2 mr-3 -mt-0.5">🚚</p>
              <div>
                <h5 className="text-md font-medium mb-2">{currency === 'GBP' ? 'Postcodes' : 'Zipcode'} selection</h5>
                <p className="text-sm text-slate-500 leading-6">
                  Select the {currency === 'GBP' ? 'postcodes' : 'zipcodes'} you want to promote in (only one florist can be promoted per {currency === 'GBP' ? 'postcode' : 'zipcode'}).
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="mb-2 mr-3 -mt-0.5">💸</p>
              <div>
                <h5 className="text-md font-medium mb-2">Payment</h5>
                <p className="text-sm text-slate-500 leading-6">
                  You&apos;ll have three minutes, to complete payment before {currency === 'GBP' ? 'postcodes' : 'zipcodes'} are released for others to buy, so have your preferred card details ready.
                </p>
              </div>
            </div>
            <div className="flex">
              <p className="mb-2 mr-3 -mt-0.5">🚀</p>
              <div>
                <h5 className="text-md font-medium mb-2">Promotion</h5>
                <p className="text-sm text-slate-500 leading-6">
                  Wait for the period to go live to see your bespoke segment show first in your promoted {currency === 'GBP' ? 'postcodes' : 'zipcodes'}! Your segment will appear for all customers for two weeks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg mb-5">
        <div className="pl-4 py-6 sm:pl-6">
          <div
            style={{ height: '355px', backgroundColor: '#FFEFE9' }}
            className="relative py-3.5 pl-6 rounded-l-md"
          >
            <div
              style={{ top: '-15px', left: '-10px' }}
              className="text-xl absolute -rotate-12 mb-4 inline-flex items-center rounded-md bg-blue-50 px-3 pt-1 pb-1.5 font-bold text-blue-700 ring-1 ring-inset ring-blue-600/20"
            >
              Preview
            </div>
            {!products && (
              <div className="animate-pulse flex items-start">
                <div
                  style={{ width: '200px', height: '315px' }}
                  className="pr-4 pt-4 text-gray-800"
                >
                  <p className="bg-gray-300 h-4 mb-2 w-1/4 rounded-3xl" />
                  <p className="bg-gray-300 rounded h-4 mb-2" />
                  <p className="bg-gray-300 rounded h-4 w-4/6" />
                </div>
                <div
                  style={{ width: 'calc(100% - 200px)', height: '315px' }}
                  className="overflow-x-scroll flex flex-nowrap"
                >
                  {[1, 2, 3, 4].map((_, i) => (
                    <div
                      style={{ flex: '0 0 210px' }}
                      className="mr-3 animate-pulse"
                      key={i}
                    >
                      <div className="relative mb-3">
                        <div className="rounded-md bg-gray-300 aspect-square" />
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="rounded h-4 bg-gray-300 w-2/3 mb-2" />
                        <div className="rounded h-4 bg-gray-300 w-5/6" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {(!!products && products.length > 0) && (
              <div className="flex items-start">
                <div
                  style={{ width: '200px', height: '315px' }}
                  className="pr-4 pt-4 text-gray-800"
                >
                  <p
                    style={{ marginLeft: '-11px' }}
                    className="mb-2 inline-block px-3 pt-1 pb-1.5 bg-white rounded-3xl text-xs"
                  >
                    Promoted
                  </p>
                  <p
                    className="mb-2 text-xl leading-6 font-bold"
                    style={{ fontSize: '21px' }}
                  >
                    Discover<br />{merchantStore!.merchant!.title}
                  </p>
                </div>
                <div
                  style={{
                    width: 'calc(100% - 200px)',
                    height: '325px'
                  }}
                  className="overflow-x-scroll flex flex-nowrap"
                >
                  {[...products, ...products, ...products, ...products].slice(0, 4).map((product, i) => {
                    const image = product.variations?.[0].media?.[0]?.src;

                    return (
                      <div
                        style={{ flex: '0 0 210px', maxWidth: '220px', fontSize: '15px' }}
                        className="mr-3"
                        key={i}
                      >
                        <div className="relative mb-3">
                          <ImgixImage
                            params={{
                              fit: 'crop',
                              ar: '1:1'
                            }}
                            config={{
                              path: image || ''
                            }}
                            width={300}
                            height={300}
                          />
                          {/* {!!product.merchant.reviewRating && !!product.merchant.reviewCount && product.merchant.reviewCount > 3 && (
                            <div className="absolute bottom-0 right-0 left-0">
                              <ReviewStars rating={product.merchant.reviewRating} />
                            </div>
                          )} */}
                        </div>
                        <div className="text-center">
                          <div className="mb-3">
                            <p className="mb-1">{product.title}</p>
                            <p className="font-bold truncate">
                              <span className="text-xs mr-0.5">By</span> {product.merchant.title}
                            </p>
                          </div>
                          <div className="bg-white rounded-sm border border-slate-200 text-xs font-bold pt-1 pb-1.5">
                            Add to basket
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotedOverview;
