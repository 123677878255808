import {
  OrderWhereInput
} from 'generated-types.d';

import { TimeService } from 'lib';

import {
  OrderGroupOption,
  OrderPageRoute,
  OrderStatusSlug
} from 'features/orders/orders.types';

import {
  statusBySlug,
  deliverAtDateRange,
  filterByStatuses,
  peakDateRange
} from './order-filter-helpers';

export interface OrderListConfig {
  heading: string;
  subHeading?: string;
  title: string;
  groups: OrderGroupOption[];
  internalGroups?: OrderGroupOption[];
  globalFilter: string;
  numericFilter: (timezone?: string) => string;
  dateRangeFilter: (timezone?: string) => string;
  numericFilterGraphQL: (timezone?: string) => OrderWhereInput;
  defaultIndex: string;
  showChecks?: boolean;
}

export type OrderListPageLayouts = {
  [orderPageRoute in OrderPageRoute]: OrderListConfig;
};

export type OrderGroupOptions = {
  [groupOption in OrderGroupOption]: {
    title?: string;
    hasStatusAction: boolean;
    filter: string;
    numericFilter: (timezone?: string) => string;
  }
};

export const ORDER_GROUP_OPTIONS: OrderGroupOptions = {
  withFlorist: {
    title: 'With Florist',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.Open),
    numericFilter: () => ''
  },
  prepared: {
    title: 'Prepared',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.Prepared),
    numericFilter: () => ''
  },
  onTheWay: {
    title: 'On the way',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.OnTheWay),
    numericFilter: () => ''
  },
  delivered: {
    title: 'Delivered',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.Delivered),
    numericFilter: () => ''
  },
  failedDelivery: {
    title: 'Failed Delivery',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.FailedDelivery),
    numericFilter: () => ''
  },
  cancelled: {
    title: 'Cancelled',
    hasStatusAction: true,
    filter: statusBySlug(OrderStatusSlug.Cancelled),
    numericFilter: () => ''
  },
  today: {
    title: 'Today',
    hasStatusAction: true,
    filter: filterByStatuses(
      OrderStatusSlug.Open,
      OrderStatusSlug.Prepared,
      OrderStatusSlug.OnTheWay
    ),
    numericFilter: timezone => deliverAtDateRange(0, 0, timezone)
  },
  tomorrow: {
    title: 'Tomorrow',
    hasStatusAction: true,
    filter: '',
    numericFilter: timezone => deliverAtDateRange(1, 1, timezone)
  },
  followingMonth: {
    title: 'Next 45 Days',
    hasStatusAction: true,
    filter: '',
    numericFilter: timezone => deliverAtDateRange(2, 45, timezone)
  },
  peak: {
    title: `Valentine's Day (14th February)`,
    hasStatusAction: true,
    filter: '',
    numericFilter: () => peakDateRange()
  },
  pastOrders: {
    title: 'All past orders',
    hasStatusAction: true,
    filter: filterByStatuses(OrderStatusSlug.Cancelled, OrderStatusSlug.Delivered),
    numericFilter: () => ''
  },
  pastActiveOrders: {
    title: 'Opened orders requiring attention',
    hasStatusAction: true,
    filter: filterByStatuses(
      OrderStatusSlug.FailedDelivery,
      OrderStatusSlug.Open,
      OrderStatusSlug.Prepared,
      OrderStatusSlug.OnTheWay
    ),
    numericFilter: () => ''
  },
  searchResults: {
    title: 'Search Results',
    hasStatusAction: true,
    filter: '',
    numericFilter: () => ''
  },
  allResults: {
    title: 'Results',
    hasStatusAction: true,
    filter: '',
    numericFilter: () => ''
  }
};

export const ORDER_LIST_PAGE_LAYOUTS: OrderListPageLayouts = {
  today: {
    heading: 'Today\'s Orders',
    title: 'today',
    globalFilter: '',
    numericFilter: timezone => deliverAtDateRange(0, 0, timezone),
    dateRangeFilter: timezone => deliverAtDateRange(0, 0, timezone),
    numericFilterGraphQL: timezone => ({
      AND: [
        { deliverAt_gte: TimeService.getStartOfDay(0, 'iso', timezone) },
        { deliverAt_lte: TimeService.getEndOfDay(0, 'iso', timezone) }
      ]
    }),
    groups: [
      'withFlorist',
      'prepared',
      'onTheWay',
      'delivered',
      'cancelled',
      'failedDelivery'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX!,
    showChecks: true
  },
  tomorrow: {
    heading: 'Tomorrow\'s Orders',
    title: 'tomorrow',
    globalFilter: '',
    numericFilter: timezone => deliverAtDateRange(1, 1, timezone),
    dateRangeFilter: timezone => deliverAtDateRange(1, 1, timezone),
    numericFilterGraphQL: timezone => ({
      AND: [
        { deliverAt_gte: TimeService.getStartOfDay(1, 'iso', timezone) },
        { deliverAt_lte: TimeService.getEndOfDay(1, 'iso', timezone) }
      ]
    }),
    groups: [
      'withFlorist',
      'prepared',
      'onTheWay',
      'delivered',
      'cancelled',
      'failedDelivery'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX!
  },
  upcoming: {
    heading: 'Upcoming Orders',
    subHeading: 'Here are all your upcoming orders!',
    title: 'the next 45 days',
    globalFilter: '',
    numericFilter: () => '',
    dateRangeFilter: timezone => deliverAtDateRange(0, 45, timezone),
    numericFilterGraphQL: timezone => ({
      AND: [
        { deliverAt_gte: TimeService.getStartOfDay(0, 'iso', timezone) },
        { deliverAt_lte: TimeService.getEndOfDay(45, 'iso', timezone) }
      ]
    }),
    groups: [
      'today',
      'tomorrow',
      'followingMonth'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX_DELIVER_AT!
  },
  past: {
    heading: 'Past Orders',
    subHeading: 'Your past orders',
    title: 'all past orders',
    globalFilter: '',
    numericFilter: timezone => `deliverAtUnix < ${TimeService.getEndOfDay(-1, 'unix', timezone)}`,
    dateRangeFilter: timezone => `deliverAtUnix < ${TimeService.getEndOfDay(-1, 'unix', timezone)}`,
    numericFilterGraphQL: timezone => ({
      deliverAt_lt: TimeService.getEndOfDay(-1, 'iso', timezone)
    }),
    groups: [
      'pastActiveOrders',
      'pastOrders'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX_DELIVER_AT!
  },
  search: {
    heading: 'All orders',
    title: 'all orders',
    globalFilter: '',
    numericFilter: () => '',
    dateRangeFilter: () => '',
    numericFilterGraphQL: () => ({}),
    groups: [
      'searchResults'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX!
  },
  peak: {
    heading: `Valentine's Day`,
    subHeading: 'Orders for delivery on 14th February',
    title: `Valentine's Day`,
    globalFilter: '',
    numericFilter: () => peakDateRange(),
    dateRangeFilter: () => peakDateRange(),
    numericFilterGraphQL: () => ({ deliverAt: '2025-02-14T00:00:00+00:00' }),
    groups: [
      'withFlorist',
      'prepared',
      'onTheWay',
      'delivered',
      'cancelled',
      'failedDelivery'
    ],
    defaultIndex: process.env.ALGOLIA_FLOOMX_ORDER_INDEX_DELIVER_AT!
  }
};
